import React from 'react';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { GitHub, LinkedIn, Twitter, Mail } from '@mui/icons-material';

const socialLinks = [
  {
    name: 'GitHub',
    url: 'https://github.com/muyeen-khan',
    icon: <GitHub />,
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/muyeen-khan-0b491323b/',
    icon: <LinkedIn />,
  },
  {
    name: 'Twitter',
    url: "#",
    icon: <Twitter />,
  },
  {
    name: 'Email',
    url: 'mailto:muyeenkhan80@gmail.com',
    icon: <Mail />,
  },
];

const Contact = () => {
  return (
    <Box
      component="section"
      id="contact"
      sx={{
        py: { xs: 8, md: 10 },
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 30% 20%, rgba(127, 90, 240, 0.08) 0%, transparent 50%)',
          zIndex: 0,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '150%',
          height: '150%',
          top: '-25%',
          left: '-25%',
          background: 'radial-gradient(circle at 70% 80%, rgba(100, 255, 218, 0.05) 0%, transparent 50%)',
          transform: 'rotate(-12deg)',
          zIndex: 0,
        }
      }}
    >
      <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.8 }}
        >
          <Typography
            variant="h2"
            component="h2"
            align="center"
            sx={{
              mb: 4,
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              color: 'primary.main',
            }}
          >
            Get In Touch
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <Typography
            variant="body1"
            align="center"
            sx={{
              mb: 6,
              color: 'text.secondary',
              fontSize: '1.1rem',
              lineHeight: 1.8,
              maxWidth: '600px',
              mx: 'auto',
            }}
          >
            I'm currently looking for new opportunities. Whether you have a question or just want to say hi, feel free to reach out!
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <motion.div
            animate={{ y: [-5, 5, -5] }}
            transition={{ repeat: Infinity, duration: 5, ease: "easeInOut" }}
          >
            <Stack
              direction="row"
              spacing={3}
              justifyContent="center"
              sx={{ mb: 4 }}
            >
              {socialLinks.map((link, index) => (
                <motion.div
                  key={link.name}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: "-100px" }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  <IconButton
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'text.secondary',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        color: 'primary.main',
                        transform: 'translateY(-5px)',
                      },
                    }}
                  >
                    {link.icon}
                  </IconButton>
                </motion.div>
              ))}
            </Stack>
          </motion.div>
        </motion.div>
       
      </Container>
    </Box>
  );
};

export default Contact;
