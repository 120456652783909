import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Chip,
  Stack,
  IconButton,
  Paper,
} from '@mui/material';
import { GitHub, Launch, Folder } from '@mui/icons-material';
import { motion } from 'framer-motion';

const projects = [
  {
    title: 'Personal Portfolio',
    description:
      'A modern, responsive portfolio website built with React and Material-UI, featuring smooth animations and a dark theme.',
    technologies: ['React', 'TypeScript', 'Material-UI', 'Framer Motion'],
    github: 'https://github.com/muyeen-khan/portfolio',
    live: '#',
    image: '/project-images/portfolio.png', 
    gradient: 'linear-gradient(120deg, rgba(100, 255, 218, 0.1) 0%, rgba(127, 90, 240, 0.1) 100%)',
  },
  {
    title: 'E-Commerce Platform',
    description:
      'A full-stack e-commerce platform with user authentication, product catalog, shopping cart, and secure payment integration.',
    technologies: ['React', 'Node.js', 'MongoDB', 'Express'],
    github: 'https://github.com/muyeen-khan/ecommerce',
    live: '#',
    image: '/project-images/e.jpg', 
    gradient: 'linear-gradient(120deg, rgba(127, 90, 240, 0.1) 0%, rgba(100, 255, 218, 0.1) 100%)',
  },
  {
    title: 'Weather Dashboard',
    description:
      'A weather application that provides real-time weather data, forecasts, and interactive maps using OpenWeather API.',
    technologies: ['React', 'OpenWeather API','Tailwind CSS'],
    github: 'https://github.com/muyeen-khan/weather-app',
    live: '#',
    image: '/project-images/w.png', 
    gradient: 'linear-gradient(120deg, rgba(100, 255, 218, 0.08) 0%, rgba(127, 90, 240, 0.15) 100%)',
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      duration: 0.5,
      ease: "easeOut"
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  },
};

const Projects = () => {
  return (
    <Box
      component="section"
      id="projects"
      sx={{
        py: { xs: 8, md: 10 },
        backgroundColor: 'background.default',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={containerVariants}
        >
          <Typography
            component={motion.h2}
            variants={itemVariants}
            sx={{
              textAlign: 'center',
              mb: 6,
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 'bold',
              color: 'text.primary',
            }}
          >
            Featured Projects
          </Typography>

          <Grid container spacing={4}>
            {projects.map((project, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div variants={itemVariants}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      height: '100%',
                      background: 'rgba(255, 255, 255, 0.05)',
                      backdropFilter: 'blur(10px)',
                      borderRadius: 2,
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 20px rgba(0,0,0,0.2)',
                      },
                    }}
                  >
                    <Box 
                      sx={{ 
                        position: 'relative', 
                        overflow: 'hidden',
                        '&:hover img': {
                          transform: 'scale(1.1)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="200"
                        image={project.image}
                        alt={project.title}
                        sx={{
                          transition: 'transform 0.5s ease-in-out',
                          objectFit: 'cover',
                          transform: 'scale(1)',
                          willChange: 'transform',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: 'rgba(0, 0, 0, 0.5)',
                          transition: 'opacity 0.3s ease-in-out',
                          opacity: 0.5,
                          '&:hover': {
                            opacity: 0.2,
                          },
                        }}
                      />
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          zIndex: 1,
                        }}
                      >
                        
                        
                      </Stack>
                    </Box>
                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
                        <Folder 
                          sx={{ 
                            fontSize: '2.5rem', 
                            color: 'rgba(100, 255, 218, 0.8)',
                            transition: 'all 0.3s ease-in-out',
                          }} 
                        />
                        <Stack direction="row" spacing={1}>
                          <IconButton 
                            size="small" 
                            href={project.github}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ 
                              color: 'text.secondary',
                              '&:hover': { color: 'primary.main' },
                            }}
                          >
                            <GitHub />
                          </IconButton>
                          <IconButton 
                            size="small" 
                            href={project.live}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ 
                              color: 'text.secondary',
                              '&:hover': { color: 'primary.main' },
                            }}
                          >
                            <Launch />
                          </IconButton>
                        </Stack>
                      </Box>
                      <Typography
                        variant="h6"
                        component="h3"
                        sx={{
                          color: 'primary.main',
                          mb: 2,
                          fontWeight: 600,
                          fontSize: '1.25rem',
                        }}
                      >
                        {project.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 3, 
                          color: 'text.secondary',
                          fontSize: '0.95rem',
                          lineHeight: 1.6,
                        }}
                      >
                        {project.description}
                      </Typography>
                      <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                        {project.technologies.map((tech) => (
                          <Chip
                            key={tech}
                            label={tech}
                            size="small"
                            sx={{
                              backgroundColor: 'rgba(127, 90, 240, 0.1)',
                              color: 'primary.main',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: 'rgba(127, 90, 240, 0.2)',
                              },
                            }}
                          />
                        ))}
                      </Stack>
                    </CardContent>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Projects;
