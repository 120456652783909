import React from 'react';
import { Box, Container, Typography, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const skills = [
  {
    category: 'Frontend',
    items: ['React', 'Tailwind CSS', 'HTML5', 'CSS3', 'JavaScript (ES6+)'],
    icon: '🎨',
    gradient: 'linear-gradient(135deg, rgba(127, 90, 240, 0.15) 0%, rgba(100, 255, 218, 0.15) 100%)',
  },
  {
    category: 'Backend',
    items: ['Node.js', 'Express.js', 'MongoDB', 'RESTful APIs'],
    icon: '⚡',
    gradient: 'linear-gradient(135deg, rgba(100, 255, 218, 0.15) 0%, rgba(127, 90, 240, 0.15) 100%)',
  },
  {
    category: 'Tools & Others',
    items: ['Git', 'Github', 'VsCode', 'Figma'],
    icon: '🛠️',
    gradient: 'linear-gradient(135deg, rgba(127, 90, 240, 0.1) 0%, rgba(100, 255, 218, 0.2) 100%)',
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      duration: 0.5,
      ease: "easeOut"
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  },
};

const Skills = () => {
  return (
    <Box
      component="section"
      id="skills"
      sx={{
        py: { xs: 8, md: 10 },
        backgroundColor: 'background.default',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={containerVariants}
        >
          <Typography
            component={motion.h2}
            variants={itemVariants}
            sx={{
              textAlign: 'center',
              mb: 6,
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 'bold',
              color: 'text.primary',
            }}
          >
            Skills & Technologies
          </Typography>

          <Grid container spacing={4}>
            {skills.map((skill, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div variants={itemVariants}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 4,
                      height: '100%',
                      background: skill.gradient,
                      backdropFilter: 'blur(10px)',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                      },
                    }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      mb: 3,
                      gap: 2 
                    }}>
                      <Typography
                        className="category-icon"
                        sx={{ 
                          fontSize: '2rem',
                          transition: 'transform 0.3s ease-in-out'
                        }}
                      >
                        {skill.icon}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ 
                          color: 'primary.main',
                          fontWeight: 600,
                        }}
                      >
                        {skill.category}
                      </Typography>
                    </Box>
                    <Box 
                      component="ul" 
                      sx={{ 
                        pl: 2,
                        listStyle: 'none',
                        '& li': {
                          position: 'relative',
                          '&::before': {
                            content: '"▹"',
                            position: 'absolute',
                            left: '-20px',
                            color: 'primary.main',
                          }
                        }
                      }}
                    >
                      {skill.items.map((item) => (
                        <Typography
                          component="li"
                          key={item}
                          sx={{
                            color: 'text.secondary',
                            mb: 1.5,
                            fontSize: '1rem',
                          }}
                        >
                          {item}
                        </Typography>
                      ))}
                    </Box>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Skills;
