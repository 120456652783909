import React from 'react';
import { Box, Typography, Container, Button, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import Typewriter from 'typewriter-effect';

const Hero = () => {
  return (
    <Box
      component="section"
      id="hero"
      sx={{
        position: 'relative',
        minHeight: '100vh',
        pt: { xs: 12, md: 8 },
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 50% 50%, rgba(127, 90, 240, 0.05) 0%, transparent 50%)',
          zIndex: 1,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '150%',
          height: '150%',
          top: '-25%',
          left: '-25%',
          background: 'radial-gradient(circle at 50% 50%, rgba(100, 255, 218, 0.03) 0%, transparent 40%)',
          transform: 'rotate(-12deg)',
          zIndex: 0,
        }
      }}
    >
      <Container sx={{ position: 'relative', zIndex: 2 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant="h6"
                sx={{ 
                  color: 'primary.main', 
                  mb: 2, 
                  fontWeight: 500,
                  mt: { xs: 4, md: 0 }
                }}
              >
                Hi, my name is
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  background: 'linear-gradient(45deg, #64ffda 30%, #7f5af0 90%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Muyeen Khan
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <Typography
                variant="h3"
                sx={{ color: 'text.secondary', mb: 4, height: '120px' }}
              >
                <Typewriter
                  options={{
                    strings: [
                      'MERN Stack Developer',
                      'Web Developer'
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 50,
                    delay: 100,
                  }}
                />
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <Typography
                variant="body1"
                sx={{ color: 'text.secondary', mb: 4, maxWidth: '600px' }}
              >
                I build exceptional and scalable full-stack applications using MongoDB, Express.js, React, and Node.js.
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, delay: 0.8 }}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                href="#projects"
                sx={{
                  borderRadius: '4px',
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  padding: '10px 30px',
                }}
              >
                Check out my work
              </Button>
            </motion.div>
          </Grid>
          <Grid 
            item 
            xs={12} 
            md={6}
            component={motion.div}
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.8, delay: 0.5 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: { xs: 4, md: 0 }
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '350px',
                height: '350px',
                margin: '0 auto',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  border: '2px solid #64ffda',
                  borderRadius: '50%',
                  zIndex: 2,
                  transition: 'all 0.3s ease-in-out',
                },
                '&:hover::before': {
                  transform: 'scale(1.1)',
                },
                '&:hover img': {
                  filter: 'grayscale(0%) brightness(110%)',
                },
              }}
            >
              <Box
                component="img"
                src="/profile.png"
                alt="Muyeen Khan"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  position: 'relative',
                  zIndex: 1,
                  transition: 'all 0.3s ease-in-out',
                  filter: 'grayscale(100%)',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
