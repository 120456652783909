import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <Box
      component="section"
      id="about"
      sx={{
        py: 10,
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  mb: 4,
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  fontWeight: 700,
                  color: 'primary.main',
                }}
              >
                About Me
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  color: 'text.secondary',
                  fontSize: '1.1rem',
                  lineHeight: 1.8,
                }}
              >
                Hello! I'm Muyeen Khan, a passionate MERN stack developer with a keen eye for creating elegant solutions. My journey in web development started with a curiosity for building things that live on the internet, and that curiosity has only grown stronger with time.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  color: 'text.secondary',
                  fontSize: '1.1rem',
                  lineHeight: 1.8,
                }}
              >
                I specialize in building exceptional digital experiences using the MERN (MongoDB, Express.js, React, Node.js) stack. My focus is on creating responsive, user-friendly applications that solve real-world problems.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <motion.div
                animate={{ y: [-10, 10, -10] }}
                transition={{ repeat: Infinity, duration: 6, ease: "easeInOut" }}
              >
                <Box
                  component="img"
                  src="/dev-illustration.svg"
                  alt="Web Development"
                  sx={{
                    width: '100%',
                    maxWidth: '500px',
                    height: 'auto',
                    filter: 'drop-shadow(0 0 20px rgba(100, 255, 218, 0.2))',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      filter: 'drop-shadow(0 0 30px rgba(100, 255, 218, 0.3))',
                      transform: 'translateY(-5px)',
                    },
                  }}
                />
              </motion.div>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
